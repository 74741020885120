import './hero-section.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimHeadline from './anim-headline';

export const HeroSection = (props) => {
	const ref = React.useRef(null);

	const scrollDown = () => {
		window.scrollTo({
			behavior: 'smooth',
			top: props.withBorder
				? ref.current.clientHeight - 40
				: ref.current.clientHeight,
		});
	};
	return (
		<section
			ref={ref}
			className={`hero ${props.withBorder ? 'hero-with-border' : ''}`}
			style={{ background: props.bgColor, color: props.textColor }}
		>
			<div className="content">
				{props.words && <AnimHeadline words={props.words} />}
				{props.title && <h2>{props.title}</h2>}
				<h1 className="hs-text-3">
					<p style={{ lineHeight: '175%' }}>
						<b>{props.children}</b>
					</p>
				</h1>
				<span className="scroll-down-arrow">
					<button onClick={scrollDown} style={{ color: props.textColor }}>
						<FontAwesomeIcon icon="chevron-down" size="lg" />
					</button>
				</span>
			</div>
		</section>
	);
};
HeroSection.defaultProps = {
	textColor: 'white',
};
