import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import FeaturedPeople from '../components/featured-people';
import ContactWidget from '../components/contact-widget';
import Footer from '../components/footer';
import AnimHeadline from '../components/anim-headline';
import * as styles from './about.module.scss';
import Layout, { PageColors, PageTextColors } from '../components/layout';

import aboutImg from '../images/about.png';
import uxgSlug from '../images/uxg-slug.png';
import uxgHeaderLogoLeft from '../images/about/uxg-header-logo-left.png';
import uxgHeaderLogoRight from '../images/about/uxg-header-logo-right.png';
import uxologyGroupLogo from '../images/uxology-group-logo.png';
import uxgContactLeft from '../images/about/contact-left.png';
import uxgContactRight from '../images/about/contact-right.png';
import { HeroSection } from '../components/hero-section';

export default class AboutPage extends React.Component {
	render() {
		return (
			<Layout page="about" location={this.props.location}>
				<div className={styles.servicesPage}>
					<HeroSection
						words={['PHYSICAL', 'DIGITAL', 'OMNI-CHANNEL']}
						bgColor={PageColors.about}
						textColor={PageTextColors.about}
					>
						OUR SERVICES APPLY TO ALL SORTS OF PHYSICAL, DIGITAL, AND <br />
						{` `}OMNI-CHANNEL EXPERIENCES.
						<br />
					</HeroSection>
					<div className={styles.pyramidLogos}>
						<img src={uxgHeaderLogoLeft} alt="Uxology logo" />
						<img src={uxgHeaderLogoRight} alt="Uxology logo" />
					</div>
					<section className={styles.sub}>
						<div className={styles.content}>
							<div>
								<h1>Mission Statement</h1>
								{[
									'Give clients the highest quality work, at mid-market prices',
									'Over deliver and exceed all expectations',
									'Build mutually beneficial long-term relationships',
									'Maintain the highest levels of expertise and ethics',
								].map((statement) => (
									<p className={styles.missionStatement}>
										<img src={uxgSlug} alt="UXG Slug" />
										{statement}
									</p>
								))}
							</div>
							<h1>ABOUT US</h1>
							<p>
								<img
									className={styles.backgroundLogo}
									src={uxologyGroupLogo}
									alt="Uxology logo"
								/>
								As a premium consulting firm that delivers best-in-class
								research insights, we are 100% focused on helping clients
								evaluate User Experience (UX) and improve the user-friendliness
								of their products, systems and services. With multifaceted roots
								in psychology, human factors, business development, operations,
								and management consulting, we're taking UX to new and exciting
								spaces with a business-first mindset. We're in this field to
								help companies make more informed decisions by providing high
								quality independent UX Research services that are exceedingly
								hard to find in the marketplace.
								<br />
								<br />
								The UXology Group was founded in order to address the growing
								need for high quality independent UX Research. One of our base
								premises was that in order to produce the very best work you
								need to avail yourself of the expertise of the very best
								practitioners, and that is exactly what we have done at UXG. We
								have painstakingly selected our consultants from a wide spectrum
								of targeted backgrounds and experience. All these professionals
								have not only excelled in their study of the applicable
								disciplines, but have operated at the highest levels, while
								regularly meeting and exceeding the UX needs and requirements of
								some of the most visible and largest companies in the world.
								<br />
								<br />
								In order to prepare themselves for these challenging tasks, our
								consultants hail from a wide range of advanced studies and
								experience, which, among many combinations, include the
								following disciplines:
							</p>
							<br />
							<br />
							<img className={styles.aboutImg} src={aboutImg} alt="about img" />
						</div>
					</section>

					<img
						className={styles.headerLogo}
						src={uxologyGroupLogo}
						alt="UXology logo"
					/>
					<section className={styles.sub}>
						<div className={styles.content}>
							<h1>Our Featured People</h1>
							<FeaturedPeople />
						</div>
					</section>

					<img
						className={styles.headerLogo}
						src={uxologyGroupLogo}
						alt="UXology logo"
					/>
					<section className={styles.sub}>
						<div className={styles.content}>
							<h1>Company Summary</h1>
							<p>
								<img
									className={`${styles.backgroundLogo} ${styles.companySummaryBackgroundLogo}`}
									src={uxologyGroupLogo}
									alt="Uxology logo"
								/>
								As a consulting firm, we provide a number of
								business-to-business services, all focused on helping our
								clients understand and improve the interactions they have with
								their users, both inward and outward-facing. We conduct a wide
								array of UX Research methodologies in the field, in labs, and
								remotely over web conferences throughout the United States and
								the world. Our typical clients are mid-sized or larger companies
								which understand the value of User Experience and want help
								identifying opportunities to improve it. We also do business
								with agencies, which are servicing clients of their own and need
								a specialized consultancy such as ours, in which case we are
								happy to work in collaboration with them and their clients or as
								a white-labeled service.
								<br />
								<br />
								Our offerings apply to any of these types of experiences, alone
								or in combinations; from the physical to the digital to the
								omni-channel:
							</p>

							<p>
								<ul>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Websites
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Software (includes AR/VR)
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Mobile Apps
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Kiosks
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Physical Products
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										VUIs (e.g., smart speakers, automated phone support)
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										IoT (spans physical & online experiences)
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Printed and digital documentation (e.g., installation
										manuals, user guides, etc.)
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										In-person interactions (e.g., stores, hotels, restaurants,
										etc.)
									</li>
									<li>
										<img src={uxgSlug} alt="UXG Slug" />
										Contact us today to find out more about our mission and how
										we can help you answer your toughest UX research questions!
									</li>
								</ul>
							</p>
						</div>
						<div
							className={`${styles.pyramidLogos} ${styles.contactPyramidLogos}`}
						>
							<img src={uxgContactLeft} alt="UXG slug green" />
							<img src={uxgContactRight} alt="UXG slug green" />
						</div>
					</section>

					<ContactWidget showSocial={true} />

					<Footer page="about" location={this.props.location} />
				</div>
			</Layout>
		);
	}
}
