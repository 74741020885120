import React from 'react';
import Slider from 'react-slick';
import * as styles from './featured-people.module.scss';

// import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/lib/styles.css'; //Allows for server-side rendering.

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// NOTE: Owl doesn't play nice with Gatsby's <Img> component, so we are resorting to
// static linking the deck images.

import img_02 from './static/people/0-2.jpeg';
import img_04 from './static/people/0-4.jpeg';
import img_jbryan from './static/people/jbryan.jpeg';
import img_042 from './static/people/0-4-2.jpeg';
import img_amy2 from './static/people/AMy2.png';
import img_picture11 from './static/people/Picture1-1.png';
import img_ss36 from './static/people/Screen-Shot-2018-05-30-at-12.39.36-PM.png';
import img_ss32 from './static/people/Screen-Shot-2018-05-30-at-12.48.32-PM.png';
import img_ss14 from './static/people/Screen-Shot-2018-05-30-at-12.52.14-PM.png';
import img_ss31 from './static/people/Screen-Shot-2018-08-21-at-1.08.31-AM.png';
import img_ss20 from './static/people/Screen-Shot-2018-08-23-at-9.24.20-PM.png';
import img_ss43 from './static/people/Screen-Shot-2018-09-14-at-4.38.43-AM.png';
import img_ss53 from './static/people/Screen-Shot-2018-05-30-at-1.03.53-PM.png';
import img_lisa from './static/people/photo_Lisa-Murnan.jpg';
import img_stephen from './static/people/photo_stephen.png';

export default (props) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		// mobileFirst: true
	};

	const team = (
		<>
			<div className="team-item">
				<div className="team-item-img">
					<img src={img_ss32} className="" alt="" />
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Will Howard</h4>
					<span>Partner &amp; CEO</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Will has been building businesses and operating companies for over
							50 years. One of the secrets to his success is an absolutely
							relentless focus on customer experience, which he is now
							leveraging to help take UX to new and exciting spaces.
						</p>
					</div>
				</div>
			</div>
			<div className="team-item">
				<div className="team-item-img">
					<img src={img_picture11} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Rylan Clark</h4>
					<span>Partner &amp; COO</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Rylan has spent almost 10 years executing research studies and UX
							consulting to Fortune 500 companies throughout a variety of
							industries. His philosophy toward UX is to cut through the
							academic and corporate jargon that plagues this field to focus on
							what matters: providing actionable, research-based business
							insights.
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_lisa} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Lisa Murnan</h4>
					<span>UX Coaching Consultant</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Lisa has over 20 years of experience in this field. She’s
							currently a Sr. Principal Product Designer at a publicly traded
							company and authored “How to Get a UX Design Job”, which led to
							her present UXG position where she works 1:1 with clients to
							develop and lead our coaching program.
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_stephen} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Stephen Johnson</h4>
					<span>Sr. UX Research Consultant</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Stephen has nearly 30 years of expertise in UX research, design,
							and consulting across fields ranging from media distribution to
							health care. He holds 11 patents in the field and is a frequent
							speaker at leading industry conferences.
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_ss53} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Janelle James</h4>
					<span>Sr. UX Research Consultant</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Over the past decade, Janelle has excelled as a UX Research
							Consultant, Senior E-commerce Analyst, Business Analyst, and
							Product Manager. She's a born leader with a strong sense of user
							empathy and a big picture strategic thinker who is passionate
							about all-things-UX.
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_ss43} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Mirinda Vogel</h4>
					<span>Sr. UX Research Consultant</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Mirinda has over 10 years of professional UX experience and a
							Master's degree in Psychology. She has expertly provided UX
							consulting services to numerous Fortune 500 companies throughout
							her career with a primary focus on UX research and information
							architecture.{' '}
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_jbryan} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Josiah Bryan</h4>
					<span>Business Development Consultant</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Josiah has been consulting and selling to various types of
							companies for over 15 years. His deep expertise in consultative
							selling and all-things-IT, along with an unbridled focus on
							customer experience, have served him extremely well throughout his
							successful career.
						</p>
					</div>
				</div>
			</div>

			<div className="team-item">
				<div className="team-item-img">
					<img src={img_ss14} className="" alt="" />{' '}
				</div>
				<div className="team-item-name font-second">
					<h4 className="">Lily Howard</h4>
					<span>Social Media Manager</span>
				</div>
				<div className="team-item-info">
					<div className="team-item-text">
						<p>
							Lily has successfully used her unbridled creativity, professional
							writing skills, and educational background in Global and
							International Studies to lead social media, marketing, and
							grant-writing initiatives while traveling the world. She has been
							to over 30 countries and counting.{' '}
						</p>
					</div>
				</div>
			</div>
		</>
	);

	return (
		<>
			<div className={styles.wrap}>
				{/* <div className={styles.content + " container"}> */}
				{/* <div className={styles.slider}>
					<Slider {...settings}>
						{team}
					</Slider>
				</div> */}
				<div className={styles.flat}>{team}</div>
				{/* </div> */}
			</div>
		</>
	);
};
