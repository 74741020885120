// extracted by mini-css-extract-plugin
export var servicesPage = "about-module--servicesPage--1JiIN";
export var pyramidLogos = "about-module--pyramidLogos--m5ohX";
export var contactPyramidLogos = "about-module--contactPyramidLogos--e07Ka";
export var headerLogo = "about-module--headerLogo--YoKJl";
export var missionStatement = "about-module--missionStatement--1e2rO";
export var aboutUxgLogo = "about-module--aboutUxgLogo--1-ex-";
export var sub = "about-module--sub--320sQ";
export var content = "about-module--content--1bWMI";
export var backgroundLogo = "about-module--backgroundLogo--IMRum";
export var companySummaryBackgroundLogo = "about-module--companySummaryBackgroundLogo--2nSjb";
export var uxologyGroupLogos = "about-module--uxologyGroupLogos--2vGWX";
export var aboutImg = "about-module--aboutImg--1eKrG";
export var sub2 = "about-module--sub2--1SN5T";
export var sub3 = "about-module--sub3--3zKlx";